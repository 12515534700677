<template>
  <div class="detail-container">
    <div class="title-box" :class="{ top068: !isShowNews }">
      <TitleStage :detail="planDetail" />
      <div class="plan-info">
        <div>
          <div>{{ $t("blzx.consultationId") }}：{{ planDetail.number }}</div>
          <div>{{ $t("blzx.tjTime") }}：{{ planDetail.submitDatetime }}</div>
          <div>
            {{ $t("blzx.ddStatus") }}：<span>{{
              statusDict[
                planDetail.evaluationStatus === "2"
                  ? "evaluate"
                  : planDetail.status
              ]
            }}</span>
          </div>
        </div>
        <div class="btn-box">
          <div
            v-if="
              [
                'in_process',
                're_process',
                'to_feedback',
                're_feedback',
                'to_complete'
              ].includes(planDetail.status)
            "
            class="btn-danger"
            @click="dialogTipVisible = true"
          >
            {{
              planDetail.status === "to_complete"
                ? $t("blzx.qdbgbzx")
                : $t("blzx.gbzx")
            }}
          </div>
          <div
            v-if="planDetail.evaluationStatus === '1'"
            class="btn-default"
            @click="setRateDict"
          >
            {{ $t("blzx.evaluate") }}
          </div>
          <div
            v-if="planDetail.status !== 'to_submit'"
            class="btn-default"
            @click="dialogFormVisible = true"
          >
            {{ $t("blzx.turn") }}
          </div>
        </div>
      </div>
    </div>
    <div class="container-bot" :class="{ top250: !isShowNews }">
      <div class="container-bot-left">
        <div
          v-if="
            [
              'in_process',
              're_process',
              'to_feedback',
              're_feedback',
              'to_complete'
            ].includes(planDetail.status)
          "
          class="left-title"
        >
          <span /> {{ $t("blzx.zxhf") }}
        </div>
        <div
          v-if="
            [
              'in_process',
              're_process',
              'to_feedback',
              're_feedback',
              'to_complete'
            ].includes(planDetail.status)
          "
          class="left-content"
        >
          <div style="display: flex;">
            <span class="main_theme_color_red mr4">●</span>
            {{ $t("blzx.describe") }}：
            <QuillEditor
              ref="editor"
              :qiniuToken="uploadToken"
              maxSize="20"
              placeholder="请输入"
            />
          </div>

          <div :style="{ marginLeft: lang === 'zh_CN' ? '18px' : '' }">
            <div style="display:flex;">
              {{ $t("blzx.screenshot") }}：<span
                style="margin-bottom: 10px;color: #666666;font-size: 14px"
              >
                {{ $t("blzx.screenshotTip") }}</span
              >
            </div>
            <div
              :style="{ marginLeft: lang === 'zh_CN' ? '48px' : '100px' }"
              :class="
                `${picList.length < 10 ? 'upload-box' : 'upload-box-ten'}`
              "
            >
              <el-upload
                class="upload-img-box"
                ref="uploadPic"
                :limit="10"
                :action="UPLOAD_URL"
                :before-upload="beforeUpload"
                :data="{ token: uploadToken, key: nanoid }"
                :on-error="uploadError"
                :on-success="
                  (res, file, fileList) => uploadSuccess(res, file, fileList)
                "
                :on-remove="removeUploadImg"
                list-type="picture-card"
              >
                <i slot="default" class="el-icon-plus"></i>
                <template v-slot:file-list="props">
                  <div class="img-pre-box">
                    <img
                      :src="props.file.url"
                      alt="示例图片"
                      class="centered-image"
                    />
                  </div>

                  <span class="el-upload-list__item-actions">
                    <span
                      class="el-upload-list__item-preview"
                      @click="showImg(props.file)"
                    >
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span class="el-upload-list__item-delete">
                      <i
                        class="el-icon-delete"
                        @click="removeUploadImg(props.file, props.fileList)"
                      ></i>
                    </span>
                  </span>
                </template>
              </el-upload>
            </div>
          </div>
          <div :style="{ marginLeft: lang === 'zh_CN' ? '18px' : '' }">
            <div style="display:flex;">
              {{ $t("blzx.attachment") }}：<span
                style="margin-bottom: 10px;color: #666666;font-size: 14px"
              >
                {{ $t("blzx.attachmentTip") }}</span
              >
            </div>

            <div
              class="upload-box"
              :style="{ marginLeft: lang === 'zh_CN' ? '48px' : '100px' }"
            >
              <el-upload
                ref="uploadFile"
                :action="UPLOAD_URL"
                :data="{ token: uploadToken }"
                :limit="10"
                :before-upload="file => beforeFileUpload(file)"
                class="upload-demo"
                :multiple="true"
              >
                <el-button size="small" type="primary"
                  >{{ $t(`blzx.djsc`) }}
                </el-button>
              </el-upload>
            </div>
          </div>
          <el-button class="submit" type="primary" @click="submit"
            >{{ $t(`blzx.tj`) }}
          </el-button>
        </div>
        <div class="left-title"><span />{{ $t(`blzx.gtjl`) }}</div>
        <div class="no_data" v-if="!messageList.length">
          <div class="no_data_pic"></div>
          <p class="no_data_p fz14 main_theme_color_666">
            暂时没有沟通记录哦~
          </p>
        </div>
        <div class="left-content">
          <ul class="record-text">
            <li v-for="(v, i) in messageList" :key="i">
              <div>
                <span
                  :title="
                    ['SYS', 'admin'].includes(v.creatorKind)
                      ? '隐速方案团队'
                      : v.creatorName
                  "
                  style="font-weight: 700"
                  >{{
                    ["SYS", "admin"].includes(v.creatorKind)
                      ? "隐速方案团队"
                      : v.creatorName
                  }}</span
                >
                &nbsp;&nbsp;
                <span style="font-size: 12px">{{
                  ["SALES"].includes(v.creatorKind) ? $t("blzx.sale") : ""
                }}</span
                >&nbsp;&nbsp;
                <span style="font-size: 12px">{{
                  ["DOCTOR"].includes(v.creatorKind) ? $t("blzx.doctor") : ""
                }}</span
                >&nbsp;
                <span>{{ v.createDatetime }}</span>
              </div>
              <div
                v-html="v.content"
                style="white-space: pre-line;word-break: break-word;word-wrap: break-word;"
              />
              <div
                style="display:flex;flex-wrap: wrap"
                v-if="v.photoList.length"
              >
                <div
                  class="img-pre-box"
                  v-for="(it, i) in v.photoList"
                  :key="i"
                  @click="previewPic(v.photoList, it)"
                >
                  <div class="img-pre">
                    {{ $t("cases.createImage.ck") }}
                  </div>
                  <img
                    :src="`${it.url}?imageView2/0/w/250/h/150`"
                    alt="示例图片"
                    class="centered-image"
                  />
                </div>
              </div>
              <div
                v-if="v.fileList.length"
                style="display: flex;margin-bottom: 10px;flex-wrap: wrap "
              >
                <div
                  class="file-box"
                  v-for="(it, index) in v.fileList"
                  :key="index"
                >
                  <p :title="it.fileName">{{ it.fileName }}</p>
                  <span @click="downLoadFile(it)">下载</span>
                </div>
              </div>
            </li>
          </ul>
          <el-button
            v-if="messageList.length"
            style="margin: auto; display:block;"
            type="primary"
            >{{ $t("blzx.load") }}
          </el-button>
        </div>
      </div>
      <div class="container-bot-right">
        <h3>{{ $t("blzx.fazxb") }}</h3>
        <div class="title">
          <p>{{ $t("blzx.hzxm") }}：{{ planDetail.userName }}</p>
          <p>
            {{ $t("blzx.sex") }}：{{
              planDetail.sex === "1" ? $t("blzx.nn") : $t("blzx.nv")
            }}
          </p>
          <p>{{ $t("blzx.birthday") }}：{{ planDetail.birthday }}</p>
        </div>
        <div>
          <p>1.{{ $t("blzx.zs") }}</p>
          <span
            style="word-wrap: break-word;word-break: break-word;white-space: pre-line"
            >{{ planDetail.chiefComplaint }}</span
          >
        </div>
        <div style="display: flex">
          <p>2.{{ $t("blzx.by") }}：</p>
          {{
            planDetail.toothExtraction === "true" ? $t("blzx.Y") : $t("blzx.N")
          }}
        </div>
        <div :style="{ display: lang === 'zh_CN' ? 'flex' : 'block' }">
          <p>3.{{ $t("blzx.zkd") }}：</p>
          {{ planDetail.growNailFlag === "true" ? $t("blzx.Y") : $t("blzx.N") }}
        </div>
        <div>
          <p>4.{{ $t("blzx.zyzxwt") }}</p>
          <span
            style="word-wrap: break-word;word-break: break-word;white-space: pre-line"
            >{{ planDetail.keyAdvisoryQuestions }}</span
          >
        </div>
        <div>
          <p>5.{{ $t("blzx.mxpic") }}</p>
          <div class="face-pic-container">
            <div
              v-for="(item, index) in facePic"
              v-if="planDetail[item.id] && planDetail[item.id].nanoId"
              :key="index"
              class="pic-item"
            >
              <div
                class="after-upload"
                @click="
                  () => {
                    isRecord = false;
                    showCarousel({
                      filed: item.id,
                      text: item.name,
                      index: getPicIndex(item.id)
                    });
                  }
                "
              >
                <img
                  v-if="planDetail[item.id] && planDetail[item.id].nanoId"
                  :src="
                    `${$PicPrefix}${
                      planDetail[item.id].nanoId
                    }?imageView2/0/w/360/h/250`
                  "
                  :style="{
                    transform: `rotate(${
                      planDetail[item.id]['degree']
                    }deg) scaleY(${
                      planDetail[item.id]['vertical'] ? -1 : 1
                    }) scaleX(${planDetail[item.id]['horizontal'] ? -1 : 1})`
                  }"
                  alt=""
                  class="after-upload-img"
                />
                <p class="pic_look">
                  <img alt="" src="/static/images/createCases/check_icon.png" />
                  <span>{{ $t("cases.createImage.ck") }}</span>
                </p>
              </div>
              <div class="img-name">{{ item.name }}</div>
            </div>
          </div>
        </div>

        <div>
          <p>6.{{ $t("blzx.xpic") }}</p>
          <div class="xray-pic-container">
            <div
              v-for="(item, index) in XRayPic"
              v-if="planDetail[item.id] && planDetail[item.id].nanoId"
              :key="index"
              class="pic-item"
            >
              <div
                class="after-upload"
                @click="
                  () => {
                    isRecord = false;
                    showCarousel({
                      filed: item.id,
                      text: item.name,
                      index: getPicIndex(item.id)
                    });
                  }
                "
              >
                <img
                  v-if="planDetail[item.id] && planDetail[item.id].nanoId"
                  :src="
                    `${$PicPrefix}${
                      planDetail[item.id].nanoId
                    }?imageView2/0/w/360/h/250`
                  "
                  :style="{
                    transform: `rotate(${
                      planDetail[item.id]['degree']
                    }deg) scaleY(${
                      planDetail[item.id]['vertical'] ? -1 : 1
                    }) scaleX(${planDetail[item.id]['horizontal'] ? -1 : 1})`
                  }"
                  alt=""
                  class="after-upload-img"
                />
                <p class="pic_look">
                  <img alt="" src="/static/images/createCases/check_icon.png" />
                  <span>{{ $t("cases.createImage.ck") }}</span>
                </p>
              </div>
              <div class="img-name">{{ item.name }}</div>
            </div>
          </div>
        </div>
        <div>
          <p>7.{{ $t("cases.maintain.yhmx") }}</p>
          <div style="margin-bottom: 10px;">
            {{ $t("cases.createImage.szmxwz") }}
            <span style="color:#999;">{{ fileDict[planDetail.stlFile] }}</span>
          </div>
          <div v-for="(v, i) in workOrderAttach" :key="i">
            <p style="margin-bottom: 5px;" :key="i">
              {{ $t("cases.createImage.wj") }}：{{ v.fileName.split("_")[1] }}
              <span
                @click="downLoadFile(v)"
                style="margin-left: 20px;color:#FCC80E; cursor: pointer;"
                >{{ $t("common.common.xz") }}</span
              >
            </p>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogTableVisible"
      class="rate-dialog"
      :title="$t('blzx.evaluate')"
      :width="lang === 'zh_CN' ? '812' : '902px'"
    >
      <p style="margin-bottom: 10px; color: #999">
        {{ $t("blzx.evaluateTip") }}
      </p>
      <el-table :data="rateData" border size="small">
        <el-table-column
          label=""
          property="title"
          :width="lang === 'zh_CN' ? '150' : '240'"
        ></el-table-column>
        <el-table-column
          :label="`0 ${$t('blzx.bmy')}`"
          property="name"
          width="200"
        >
          <template slot-scope="scope">
            <div
              :class="
                `rate-box ${
                  rateDict[scope.row.type] === '0'
                    ? 'rate-choose'
                    : 'rate-default'
                }`
              "
              @click="handelRate(scope.row.type, '0')"
            ></div>
          </template>
        </el-table-column>
        <el-table-column label="1" property="name" width="60">
          <template slot-scope="scope">
            <div
              :class="
                `rate-box ${
                  rateDict[scope.row.type] === '1'
                    ? 'rate-choose'
                    : 'rate-default'
                }`
              "
              @click="handelRate(scope.row.type, '1')"
            ></div>
          </template>
        </el-table-column>
        <el-table-column label="3" property="name" width="60">
          <template slot-scope="scope">
            <div
              :class="
                `rate-box ${
                  rateDict[scope.row.type] === '3'
                    ? 'rate-choose'
                    : 'rate-default'
                }`
              "
              @click="handelRate(scope.row.type, '3')"
            ></div>
          </template>
        </el-table-column>
        <el-table-column label="5" property="name" width="60">
          <template slot-scope="scope">
            <div
              :class="
                `rate-box ${
                  rateDict[scope.row.type] === '5'
                    ? 'rate-choose'
                    : 'rate-default'
                }`
              "
              @click="handelRate(scope.row.type, '5')"
            ></div>
          </template>
        </el-table-column>
        <el-table-column label="7" property="name" width="60">
          <template slot-scope="scope">
            <div
              :class="
                `rate-box ${
                  rateDict[scope.row.type] === '7'
                    ? 'rate-choose'
                    : 'rate-default'
                }`
              "
              @click="handelRate(scope.row.type, '7')"
            ></div>
          </template>
        </el-table-column>
        <el-table-column
          :label="`9 ${$t('blzx.my')}`"
          property="name"
          width="200"
        >
          <template slot-scope="scope">
            <div
              :class="
                `rate-box ${
                  rateDict[scope.row.type] === '9'
                    ? 'rate-choose'
                    : 'rate-default'
                }`
              "
              @click="handelRate(scope.row.type, '9')"
            ></div>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogTableVisible = false">{{
          $t("casesDetail.casesChildren.common.qx")
        }}</el-button>
        <el-button type="primary" @click="evaluateRun">{{
          $t("blzx.qr")
        }}</el-button>
      </span>
    </el-dialog>
    <!--    转病例-->
    <el-dialog
      :title="$t('blzx.turn')"
      :visible.sync="dialogFormVisible"
      :width="lang === 'zh_CN' ? '500px' : '600px'"
    >
      <el-form :model="form" :rules="rules" ref="ruleForm">
        <el-form-item
          :label="$t('blzx.product')"
          :label-width="lang === 'zh_CN' ? '120px' : '210px'"
          prop="productId"
        >
          <el-select
            style="width: 300px;"
            v-model="form.productId"
            :placeholder="$t('blzx.selProduct')"
            @change="changeProduct"
          >
            <el-option
              v-for="v in productList"
              :key="v.id"
              :label="v.name"
              :value="v.id"
            >
              <span style="float: left">{{ v.name }}</span>
              <span
                style="float: right"
                v-if="v.unSignedClinicList && v.unSignedClinicList.length"
              >
                <el-popover
                  :tabindex="200"
                  ref="popover1"
                  placement="bottom-end"
                  width="200"
                  trigger="hover"
                >
                  <p class="mb10 fz12 main_theme_color_333">
                    {{ $t("common.common.jgwqy") }}
                  </p>
                  <p
                    class="fz12 main_theme_color_999"
                    v-for="(it, inx) in v.unSignedClinicList"
                    :key="inx"
                  >
                    {{ it.name }}
                  </p>
                  <i
                    slot="reference"
                    class="el-icon-warning-outline main_theme_color"
                  ></i>
                </el-popover>
              </span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('blzx.bindProduct')"
          :label-width="lang === 'zh_CN' ? '120px' : '210px'"
          prop="bindProductId"
          v-if="isBind"
        >
          <el-select
            style="width: 300px;"
            v-model="form.bindProductId"
            :placeholder="$t('blzx.selBind')"
            @change="changeBind"
          >
            <el-option
              v-for="(v, i) in bindList"
              :label="v.name"
              :key="i"
              :value="v.id"
              >{{ v.name }}
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('blzx.clinic')"
          :label-width="lang === 'zh_CN' ? '120px' : '210px'"
          prop="clinicId"
        >
          <el-select
            style="width: 300px;"
            v-model="form.clinicId"
            :placeholder="$t('blzx.selClinic')"
            @change="isNeedRdt"
          >
            <el-option
              v-for="v in clinicFormList"
              :label="v.clinicInfo.name"
              :value="v.clinicId"
              :key="v.clinicId"
              >{{ v.clinicInfo.name }}
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="isRdt"
          :label-width="lang === 'zh_CN' ? '120px' : '210px'"
          :label="$t('blzx.idRdtHelp')"
          prop="helpFlag"
        >
          <el-radio-group v-model="form.helpFlag">
            <el-radio label="1">{{ $t("blzx.need") }}</el-radio>
            <el-radio label="0">{{ $t("blzx.noNeed") }}</el-radio>
          </el-radio-group>
          <div
            style="font-size: 13px;cursor:pointer; color:#fbd051;width: 120px;"
            @click="jumpClinicalPreferences"
          >
            {{ $t("casesDetail.clinicalPreferences.smsRTD") }}
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false"
          >{{ $t("casesDetail.casesChildren.common.qx") }}
        </el-button>
        <el-button type="primary" @click="turnCase">
          {{ $t("blzx.qr") }}
        </el-button>
      </div>
    </el-dialog>
    <el-dialog width="500px" center>
      <div>
        <img
          style="display: block;margin: auto auto 10px;"
          src="../../common/imgs/consultation/tip.png"
          alt=""
        />
      </div>
      <p style="text-align: center">
        关闭咨询是不可退回的操作，确定要关闭咨询吗？
      </p>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogTipVisible = false">取 消</el-button>
        <el-button type="primary" @click="closePlan">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible" width="500px" center>
      <div class="img-pre-box" style="width: 450px;height: 450px;margin: auto;">
        <img :src="dialogImageUrl" alt="示例图片" class="centered-image" />
      </div>
      <!--      <img width="100%" :src="dialogImageUrl" alt="" />-->
    </el-dialog>
    <confirm-modal
      :confirmObj="{
        tipText: $t('blzx.closeTip'),
        backText: $t('casesDetail.casesChildren.common.qx'),
        okText: $t(`blzx.confirmClose`)
      }"
      @confirmSubmit="closePlan()"
      @confirmHide="dialogTipVisible = false"
      v-show="dialogTipVisible"
    />
    <PreviewImg
      ref="previewImg"
      :carouselList="!isRecord ? carouselList : recordPicList"
      @imgChange="imgChange"
    />
    <FullLoading v-show="isLoading" />
  </div>
</template>

<script>
import FullLoading from "components/full-loading/full-loading";

import TitleStage from "@/pages/consultation/title-stage/index.vue";
import { extend, filter, map, pickBy } from "lodash";
import { UPLOAD_URL } from "common/js/config";
import { notifyMsg } from "common/js/util";
import PreviewImg from "components/preview-image/preview-image.vue";
import { getQiniuToken } from "common/api/public";
import { nanoid } from "nanoid";
import { file } from "jszip";
import {
  clinicDoctorList,
  optionalListByProduct,
  schemeClose,
  schemeDetail,
  schemeEvaluate,
  schemeMessagePage,
  schemeReply,
  schemeTurnCase
} from "common/api/cases";
import { saveAs } from "file-saver";
import * as QINIU from "qiniu-js";
import ConfirmModal from "components/confirm-modal/confirm.vue";
import { mapState } from "vuex";
import QuillEditor from "@/components/quill-editor";
// messageRun({ pageSize: messageSize, pageNum: 1, refId: id });

export default {
  data() {
    return {
      lang: localStorage.getItem("user_lang") || "zh_CN",
      isRecord: false,
      recordPicList: [],
      intervalId: null,
      isLoading: false,
      rules: {
        productId: [
          {
            required: true,
            message: this.$t("blzx.selProduct"),
            trigger: "blur"
          }
        ],
        clinicId: [
          {
            required: true,
            message: this.$t("blzx.selClinic"),
            trigger: "blur"
          }
        ],
        helpFlag: [
          { required: true, message: this.$t("blzx.selRdt"), trigger: "blur" }
        ],
        bindProductId: [
          { required: true, message: this.$t("blzx.selBind"), trigger: "blur" }
        ]
      },
      // ['1',6,5] cureType!=='F'
      formLabelWidth: "120px",
      dialogFormVisible: false,
      form: {
        productId: "",
        clinicId: "",
        helpFlag: "",
        bindProductId: ""
      },
      clinicFormList: [],
      productList: [],
      bindTypeList: [],
      bindList: [],
      isBind: false,
      clinicList: [],
      dialogTipVisible: false,
      dialogTableVisible: false,
      dialogVisible: false,
      dialogImageUrl: "",
      rateData: [
        { title: this.$t("blzx.overall"), type: "overall_experience" },
        {
          title: this.$t("blzx.isHelp"),
          type: "consultation_service"
        },
        { title: this.$t("blzx.speed"), type: "response_speed" },
        { title: this.$t("blzx.attitude"), type: "service_attitude" }
      ],
      rateDict: {
        overall_experience: "",
        consultation_service: "",
        response_speed: "",
        service_attitude: ""
      },
      pageSize: 10,
      messageList: [],
      id: "",
      nanoid: nanoid(),
      picList: [],
      fileDict: {
        local_upload: this.$t("blzx.upload"),
        iTero: this.$t("blzx.iTero"),
        other: this.$t("blzx.other")
      },
      statusDict: {
        to_accepts: this.$t("blzx.toAcc"),
        in_process: this.$t("blzx.inPro"),
        re_process: this.$t("blzx.inPro"),
        to_feedback: this.$t("blzx.toFeed"),
        re_feedback: this.$t("blzx.toFeed"),
        to_complete: this.$t("blzx.toCom"),
        to_close: this.$t("blzx.closed"),
        closed: this.$t("blzx.toEva"),
        evaluate: this.$t("blzx.evaluated")
      },
      fileList: [],
      uploadToken: "",
      carouselList: [],
      UPLOAD_URL,
      planDetail: {},
      editorContent: "",
      workOrderAttach: [],
      content: "",
      resultObj: {
        retainer: "",
        alignerBasedOn: "",
        materialSelection: "",
        removeAttachment: "",
        aestheticCutting: "",
        reservedPosition: "",
        occlusalGuide: "",
        sideProfile: "",
        frontal: "",
        frontalSmile: "",
        upperArch: "",
        anteriorOverjet: "",
        lowerArch: "",
        intraOralRight: "",
        intraOralFrontal: "",
        intraOralLeft: "",
        panoramicRadiograph: "",
        lateralCephalogram: "",
        dentalModel: "",
        siliconeImpression: "",
        stlFile: "",
        attachmentList: []
      },
      imgList: [
        "sideProfile",
        "frontal",
        "frontalSmile",
        "upperArch",
        "anteriorOverjet",
        "lowerArch",
        "intraOralRight",
        "intraOralFrontal",
        "panoramicRadiograph",
        "lateralCephalogram",
        "intraOralLeft"
      ],
      imgDict: {
        sideProfile: this.$t("cases.createImage.cmx"),
        frontal: this.$t("cases.createImage.zmx"),
        frontalSmile: this.$t("cases.createImage.wxx"),
        upperArch: this.$t("cases.createImage.sylx"),
        anteriorOverjet: this.$t("cases.createImage.qyfhfgx"),
        lowerArch: this.$t("cases.createImage.xylx"),
        intraOralRight: this.$t("cases.createImage.knzcyx"),
        intraOralFrontal: this.$t("cases.createImage.knzwx"),
        panoramicRadiograph: this.$t("cases.createImage.dcp"),
        lateralCephalogram: this.$t("cases.createImage.dwp"),
        intraOralLeft: this.$t("cases.createImage.knycyx")
      },
      facePic: [
        {
          id: "sideProfile",
          name: this.$t("cases.createImage.cmx"),
          label: "cmx"
        },
        {
          id: "frontal",
          name: this.$t("cases.createImage.zmx"),
          label: "zmx"
        },
        {
          id: "frontalSmile",
          name: this.$t("cases.createImage.wxx"),
          label: "wxx"
        },
        {
          id: "upperArch",
          name: this.$t("cases.createImage.sylx"),
          label: "sylx"
        },
        {
          id: "anteriorOverjet",
          name: this.$t("cases.createImage.qyfhfgx"),
          label: "qyfhfgx"
        },
        {
          id: "lowerArch",
          name: this.$t("cases.createImage.xylx"),
          label: "xylx"
        },
        {
          id: "intraOralRight",
          name: this.$t("cases.createImage.knzcyx"),
          label: "knzcyx"
        },
        {
          id: "intraOralFrontal",
          name: this.$t("cases.createImage.knzwx"),
          label: "knzwx"
        },
        {
          id: "intraOralLeft",
          name: this.$t("cases.createImage.knycyx"),
          label: "knycyx"
        }
      ],
      XRayPic: [
        {
          id: "panoramicRadiograph",
          name: this.$t("cases.createImage.dcp"),
          label: "dcp"
        },
        {
          id: "lateralCephalogram",
          name: this.$t("cases.createImage.dwp"),
          label: "dwp"
        }
      ],
      productId: "",
      isRdt: "",
      bindProductNumber: ""
    };
  },
  components: {
    QuillEditor,
    ConfirmModal,
    PreviewImg,
    TitleStage,
    FullLoading
  },
  watch: {
    dialogFormVisible(newValue) {
      if (!newValue) {
        this.form = {
          productId: "",
          clinicId: "",
          helpFlag: "",
          bindProductId: ""
        };
        this.isBind = false;
        this.productId = "";
        this.isRdt = "";
        this.bindProductNumber = "";
        this.$refs.ruleForm.resetFields();
      }
    }
  },
  computed: {
    ...mapState({
      isShowNews: state => state.isShowNews
    })
  },
  methods: {
    jumpClinicalPreferences() {
      sessionStorage.setItem("isShowRTD", true);
      const href = "/cases/clinicalPreferences";
      window.open(href, "_blank");
    },
    previewPic(fileList, file) {
      this.isRecord = true;
      this.recordPicList = fileList.map((v, i) => {
        return {
          ETag: v.key,
          degree: 0,
          horizontal: false,
          index: i.toString(),
          name: v.fileName,
          filed: v.id,
          nanoId: v.key,
          vertical: false
        };
      });
      this.showCarousel({
        filed: file,
        text: file.name,
        index: this.getPicIndex(file.id)
      });
    },
    turnCase() {
      this.$refs.ruleForm.validate(val => {
        if (val) {
          this.isLoading = true;
          const formParams = {
            ...this.form,
            number: this.bindProductNumber,
            id: this.id,
            doctorId: localStorage.getItem("userId")
          };
          let route = this.$route;
          schemeTurnCase(formParams)
            .then(data => {
              this.isLoading = false;
              this.$router.replace({
                path: "/cases/create",
                query: extend(
                  {},
                  pickBy({
                    caseId: data.id,
                    curePlanId: data.currentCurePlanId,
                    productType: data.cureType,
                    productId: data.productId
                  })
                )
              });
            })
            .catch(() => {
              this.isLoading = false;
            });
        }
      });
    },
    changeProduct(v) {
      this.productId = v;
      this.form.clinicId = undefined;
      this.isBind = this.bindTypeList.includes(v);
      let bindList = [];
      let clinic = [];
      this.form.bindProductId = "";
      this.productList.forEach(it => {
        if (it.id === v) {
          bindList = it?.bindProductList?.filter(val => val?.cureType !== "F");
          it?.unSignedClinicList?.forEach(item => {
            clinic.push(item.id);
          });
        }
      });
      this.clinicFormList = this.clinicList.filter(
        it => !clinic.includes(it.clinicId)
      );
      this.bindList = bindList;
      this.isRdt = "";
    },
    changeBind(val) {
      this.bindList.forEach(v => {
        if (val === v.id) {
          this.bindProductNumber = v.number;
        }
      });
      this.form.clinicId = "";
      this.isRdt = "";
    },
    isNeedRdt(val) {
      // isBind
      const productId = this.isBind ? this.form.bindProductId : this.productId;
      let clinicObj;
      this.clinicList.forEach(value => {
        if (value.clinicId === val) {
          clinicObj = value;
        }
      });
      if (clinicObj) {
        clinicObj?.clinicInfo?.productList?.forEach(v => {
          if (v.id === productId) {
            this.isRdt = v?.clinicProduct?.rdtId || "";
          }
        });
      }
    },
    evaluateRun() {
      this.isLoading = true;
      const evaluationDTOList = [];
      for (const key in this.rateDict) {
        if (this.rateDict[key]) {
          evaluationDTOList.push({
            optionKey: key,
            optionValue: this.rateDict[key]
          });
        }
      }
      const params = {
        evaluationDTOList,
        id: this.id,
        party: "sys"
      };
      schemeEvaluate(params)
        .then(() => {
          schemeDetail(this.id).then(res => {
            this.setFormData(res);
            this.isLoading = false;
            this.dialogTableVisible = false;
          });
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    closePlan() {
      schemeClose({ id: this.id, party: "sub" })
        .then(() => {
          this.dialogTipVisible = false;
          schemeDetail(this.id).then(res => {
            this.isLoading = false;
            this.setFormData(res);
          });
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    // getContent(val) {
    //   console.log(val);
    // },
    setRateDict() {
      this.rateDict = {
        overall_experience: "",
        consultation_service: "",
        response_speed: "",
        service_attitude: ""
      };
      this.dialogTableVisible = true;
    },
    handelRate(type, val) {
      this.rateDict[type] = this.rateDict[type] === val ? "" : val;
    },
    submit() {
      if (!this.$refs.editor.content) {
        return notifyMsg(this, "warning", "请填写描述");
      }
      this.isLoading = true;
      const params = {
        attachmentList: [...this.picList, ...this.fileList],
        content: this.$refs.editor.content,
        id: this.id,
        party: "sub"
      };
      schemeReply(params)
        .then(() => {
          notifyMsg(this, "success", "提交成功");
          this.$refs.editor.content = "";
          this.$refs.uploadFile.clearFiles();
          this.$refs.uploadPic.clearFiles();
          this.picList = [];
          this.getMessage();
          schemeDetail(this.id).then(res => {
            this.setFormData(res);
            this.isLoading = false;
          });
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    file,
    imgChange(obj) {
      this.$forceUpdate();
      for (const key in this.resultObj) {
        if (obj.filed === key) {
          const current = this.resultObj[key];
          this.$set(current, "degree", obj.degree);
          this.$set(current, "horizontal", obj.horizontal);
          this.$set(current, "vertical", obj.vertical);
        }
      }
    },
    beforeFileUpload(file) {
      const _this = this;
      const { name } = file;
      let isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        notifyMsg(this, "warning", this.$t("common.message.isLt20M"));
        return false;
      } else {
        const aBlob = new Blob([file]);
        const ts = new Date().getTime();
        const observable = QINIU.upload(
          aBlob,
          ts + "_" + name,
          this.uploadToken
        );
        observable.subscribe({
          error(err) {
            // _this.uploading = false;
          },
          complete(res) {
            const obj = {
              fileName: res.key,
              fileKey: "base",
              mime: "file",
              key: res?.key,
              eTag: res?.hash,
              order: 0
            };
            _this.fileList = [..._this.fileList, obj];
            notifyMsg(_this, "success", _this.$t("common.message.wjsccg"));
          }
        });
      }
    },
    uploadFileSuccess(res, file, fileList) {
      this.fileList = fileList.map((v, i) => {
        return {
          fileName: v.name,
          fileKey: "base",
          mime: "file",
          key: v?.response?.key,
          eTag: v?.response?.hash,
          order: i
        };
      });
      notifyMsg(this, "success", this.$t("common.message.wjsccg"));
    },
    uploadError() {
      return notifyMsg(this, "error", this.$t("common.message.scsb"));
    },
    removeUploadImg(file, fileList) {
      const index = fileList.indexOf(file);
      if (index !== -1) {
        fileList.splice(index, 1);
      }
      this.$forceUpdate();
      this.picList = fileList.map((v, i) => {
        return {
          fileName: v.name,
          fileKey: "base",
          mime: "photo",
          key: v?.response?.key,
          eTag: v?.response?.hash,
          order: i
        };
      });
    },
    uploadSuccess(res, file, fileList) {
      this.$forceUpdate();
      this.picList = fileList.map((v, i) => {
        return {
          fileName: v.name,
          fileKey: "base",
          mime: "photo",
          key: v?.response?.key,
          eTag: v?.response?.hash,
          order: i
        };
      });
      notifyMsg(this, "success", this.$t("common.message.tpsccg"));
      if (this.fileList.length >= 5) {
        this.$nextTick(() => {
          const uploadBtn = document.querySelector(".el-upload__input");
          if (uploadBtn) {
            uploadBtn.style.display = "none";
          }
        });
      }
    },
    beforeUpload(file) {
      this.nanoid = nanoid();
      const supportedFormats = ["image/jpeg", "image/png", "image/bmp"];
      let isLt20M = file.size / 1024 / 1024 < 20;
      if (!supportedFormats.includes(file.type)) {
        notifyMsg(this, "warning", this.$t("common.message.supportedFormats"));
        return false;
      } else if (!isLt20M) {
        notifyMsg(this, "warning", this.$t("common.message.isLt20M"));
        return false;
      } else {
        return true;
      }
    },
    showCarousel(item) {
      this.$nextTick(() => {
        this.$refs["previewImg"] &&
          this.$refs["previewImg"].show(item.text, item.index);
      });
    },
    getPicIndex(filed) {
      const list = !this.isRecord ? this.carouselList : this.recordPicList;
      return filter(list, item => item.filed === filed)[0]?.index;
    },
    initImg(param) {
      const imgObj = {};
      for (const paramKey in param) {
        if (this.imgList.includes(paramKey)) {
          if (param[paramKey]) {
            imgObj[paramKey] = this.initObj(
              param[paramKey],
              paramKey,
              this.imgDict[paramKey]
            );
          }
        }
      }
      let echoFacePic = [
        imgObj["sideProfile"],
        imgObj["frontal"],
        imgObj["frontalSmile"],
        imgObj["upperArch"],
        imgObj["anteriorOverjet"],
        imgObj["lowerArch"],
        imgObj["intraOralRight"],
        imgObj["intraOralFrontal"],
        imgObj["intraOralLeft"]
      ];
      let echoXRayPic = [
        imgObj["panoramicRadiograph"],
        imgObj["lateralCephalogram"]
      ];
      let picList = [...echoFacePic, ...echoXRayPic];
      picList = picList.filter(item => item?.nanoId).filter(Boolean);
      this.carouselList = map(picList, (item, index) => {
        return extend({}, item, { index: index + "" });
      });
      return imgObj;
    },

    setFormData(val) {
      const imgObj = {};
      this.workOrderAttach = val.workOrderAttach;
      this.initImg(val.workOrderInfo);
      this.toothExtraction = val.workOrderInfo.toothExtraction;
      this.growNailFlag = val.workOrderInfo.growNailFlag;
      for (const paramKey in val.workOrderInfo) {
        if (this.imgList.includes(paramKey)) {
          if (val.workOrderInfo[paramKey]) {
            imgObj[paramKey] = this.initObj(
              val["workOrderInfo"][paramKey],
              paramKey,
              this.imgDict[paramKey]
            );
          }
        }
      }
      this.planDetail = { ...val.workOrderInfo, ...imgObj };
      this.resultObj = { ...this.resultObj, ...imgObj };
    },
    initObj(str, filed, name) {
      if (!str) {
        return "";
      }
      let obj = JSON.parse(str);
      !obj.nanoId && (obj.nanoId = obj.ETag);
      !obj.degree && (obj.degree = 0);
      !obj.horizontal && (obj.horizontal = false);
      !obj.vertical && (obj.vertical = false);
      obj.filed = filed;
      obj.name = name;
      return obj;
    },
    getMessage(val) {
      schemeMessagePage({
        refId: this.id,
        pageSize: this.pageSize + (val || 0),
        pageNum: 1
      }).then(data => {
        this.pageSize = this.pageSize + (val || 0);
        const _pList = [];
        this.messageList = data?.list?.map(item => {
          const _photoList = [];
          const _fileList = [];
          if (item.workOrderAttach?.length) {
            item.workOrderAttach.forEach((v, i) => {
              if (v?.mime === "photo") {
                _pList?.push({
                  ETag: v.key,
                  vertical: false,
                  nanoId: v.key,
                  horizontal: false,
                  degree: 0,
                  filed: "sideProfile",
                  index: i
                });
                _photoList?.push({
                  ...v,
                  name: v.fileName,
                  url: this.$PicPrefix + v.key
                });
              }
              if (v?.mime === "file") {
                _fileList?.push({
                  key: v?.key,
                  fileName: v?.fileName,
                  uploadTime: item.createDatetime
                });
              }
            });
          }
          return { ...item, photoList: _photoList, fileList: _fileList };
        });
      });
    },

    downLoadFile(file) {
      const key = `${this.$PicPrefix}${file.fileName}`;
      saveAs(key, file?.fileName);
    },
    showImg(file) {
      this.dialogVisible = true;
      this.dialogImageUrl = file.url;
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalId); // 组件销毁时清除定时器
  },
  mounted() {
    let query = this.$route.query;
    if (query.id) {
      this.id = query.id;
      this.isLoading = true;
      schemeDetail(query.id).then(res => {
        this.isLoading = false;
        this.setFormData(res);
      });
      this.getMessage();
      this.intervalId = setInterval(this.getMessage, 60000);
    }

    getQiniuToken().then(data => {
      this.uploadToken = data;
    });

    optionalListByProduct({ platformType: "0", type: "0" }).then(data => {
      this.productList = data.filter(v =>
        ["1", "5", "6"].includes(v.systemType)
      );
      this.bindTypeList = data
        .map(v => {
          if (v.systemType === "5" || v.systemType === "6") {
            return v.id;
          }
        })
        .filter(Boolean);
    });
    clinicDoctorList({
      doctorId: localStorage.getItem("userId"),
      status: "1"
    }).then(data => {
      this.clinicList = data;
      this.clinicFormList = [...data];
    });
  }
};
</script>

<style lang="scss" scoped>
.detail-container {
  width: 13.44rem;
  font-size: 16px;
  color: #333;
  margin: 0 auto;
  //position: relative;

  .title-box {
    transition: all 0.3s;
    background-color: #efeff4;
    position: fixed;
    z-index: 999;
    top: 103px;
    width: 13.44rem;
    height: 120px;

    overflow: hidden;
    margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 130px;

    .plan-info {
      height: 68px;
      width: 100%;
      background-color: #fff;
      display: flex;
      padding: 23px 30px;
      line-height: 32px;
      justify-content: space-between;
      align-items: center;
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;

      > div {
        display: flex;

        > div {
          margin-right: 10px;
          font-size: 14px;

          > span {
            color: #20c479;
          }
        }
      }

      .btn-box {
        display: flex;
        justify-content: flex-end;

        > div:last-child {
          margin-right: 0;
        }

        > div {
          //width: 96px;
          height: 36px;
          padding: 0 10px;
          border-radius: 4px 4px 4px 4px;
          text-align: center;
          line-height: 36px;
          cursor: pointer;
        }

        .btn-danger {
          border: 1px solid #ed4027;
          color: #ed4027;
        }

        .btn-default {
          border: 1px solid #bbbbbb;
          color: #333;
        }
      }
    }
  }

  .container-bot {
    margin-top: 2.5rem;
    //width: 100%;
    display: flex;
    transition: all 0.3s;
    //justify-content: space-between;

    .container-bot-left {
      width: 926px;
      margin-right: 10px;
      padding: 16px 30px;
      background-color: #fff;
      align-self: flex-start;
      border-radius: 6px;

      .left-title {
        width: 866px;
        height: 33px;
        font-size: 18px;
        padding-left: 14px;
        position: relative;
        line-height: 26px;
        border-bottom: 1px solid #e5e5e5;
        margin-bottom: 30px;

        > span {
          display: inline-block;
          position: absolute;
          width: 4px;
          height: 26px;
          top: 0;
          left: 0;
          background-color: #fcc80e !important;
        }
      }

      .left-content {
        color: #000000;

        > div {
          margin-bottom: 30px;

          .upload-box-ten {
            margin-left: 48px;

            /deep/ .el-upload {
              //transition: all 0.5s;
              display: none;
            }

            /deep/ .el-upload--picture-card {
              //transition: all 0.5s;
              display: none;
            }
          }

          > .upload-box {
            margin-left: 48px;
          }

          > span {
            display: inline-block;
            margin-bottom: 20px;
            color: #666666;
            font-size: 14px;
          }
        }

        > div:last-child {
          margin-bottom: 18px;
        }

        > ul {
          > li {
            width: 100%;
            border-bottom: 1px solid #e5e5e5;
            //padding-bottom: 10px;

            > div {
              font-size: 14px;
              color: #333333;
              line-height: 24px;
            }

            > div:first-child {
              font-size: 16px;
              font-weight: bold;
              color: #333333;
              //margin-bottom: 10px;

              > span:last-child {
                font-size: 12px;
                color: #999999;
                display: inline-block;
                margin-left: 10px;
              }
            }
          }
        }
      }
    }

    .container-bot-right {
      width: 408px;
      background-color: #fff;
      padding: 20px;
      font-size: 14px;
      align-self: flex-start;
      border-radius: 6px;

      > h3 {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        margin-bottom: 20px;
      }

      > div {
        color: #666;
        line-height: 24px;
        margin-bottom: 10px;

        > p {
          color: #333;
        }

        .face-pic-container,
        .xray-pic-container {
          display: flex;
          flex-wrap: wrap;

          .pic-item {
            width: 110px;
            height: 120px;
            margin: 0 10px 10px 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &:nth-child(3n + 3) {
              margin-right: 0px;
            }
          }

          .after-upload {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 240px;
            border: 1px solid #bbb;
            border-radius: 8px;
            background-color: #f0f0f0;
            position: relative;
            overflow: hidden;

            &:hover .pic_look {
              opacity: 1;
            }

            .pic_look {
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              height: 0.2rem;
              background-color: rgba(0, 0, 0, 0.6);
              color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 0.16rem;
              opacity: 0;
              transition: opacity 0.6s;
              z-index: 1;

              img {
                width: 0.24rem;
                height: 0.16rem;
                margin-right: 0.1rem;
              }
            }

            img {
              max-width: 100%;
              max-height: 100%;
            }
          }

          .img-name {
            font-size: 12px;
            line-height: 22px;
            color: #333;
            text-align: center;
            margin-top: 10px;
          }
        }
      }

      .title {
        color: #333 !important;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }
  }

  .submit {
    width: 176px;
    height: 42px;
    font-size: 16px;
    margin: auto auto 60px;
    display: block;
  }

  .rate-dialog {
    /deep/ .el-dialog__body {
      border-top: 1px #e5e5e5 solid;
      padding: 10px;
    }
  }

  .rate-box {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .rate-choose {
    background: url("../../common/imgs/consultation/choose.png") no-repeat;
    background-size: contain;
  }

  .rate-default {
    background: url("../../common/imgs/consultation/icon_default.png") no-repeat;
    background-size: contain;
  }

  .record-text {
    /deep/ .el-upload {
      display: none;
    }

    /deep/ .el-upload--picture-card {
      display: none;
    }

    > li {
      margin-bottom: 20px;

      > div:first-child {
        > span {
          max-width: 200px;
          display: inline-block;
          white-space: nowrap;
          word-wrap: break-word;
          word-break: break-word;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }

    > li:last-child {
      margin-bottom: 10px;
    }
  }
}

/deep/ .el-popover {
  padding: 10px;
}

/deep/ .el-popover--plain {
  padding: 10px !important;
}

/deep/ .el-popper {
  padding: 10px !important;
}

.file-box {
  width: 180px;
  border: 1px dashed #bbb;
  border-radius: 4px;
  padding: 5px;
  margin-right: 10px;
  margin-bottom: 10px;

  > p {
    color: #807a7a;
    word-wrap: break-word;
    word-break: break-word;
    width: 100%;
    white-space: nowrap; /* 防止文字换行 */
    overflow: hidden; /* 隐藏溢出的文字 */
    text-overflow: ellipsis; /* 显示省略号 */
  }

  > span {
    font-size: 12px;
    cursor: pointer;
    color: #fabe10;
  }
}

.no_data {
  margin: 0.92rem auto 1.6rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .no_data_pic {
    width: 2.04rem;
    height: 1.54rem;
    background-size: 100% 100%;
    background-image: url("../../common/imgs/case/case_list_empty.png");
  }

  .no_data_p {
    color: #666666;
    font-size: 0.16rem;
    margin-top: 0.15rem;
  }
}

.img-pre-box {
  cursor: pointer;
  position: relative;
  width: 150px;
  height: 150px;
  border: 1px solid #ebeef5;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-right: 10px;
  margin-bottom: 10px;

  .img-pre {
    text-align: center;
    height: 20px;
    width: 100%;
    position: absolute;
    bottom: 0;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: opacity 0.3s;
  }

  .centered-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; /* 图片按比例显示并居中 */
  }
}

.img-pre-box:hover .img-pre {
  opacity: 1;
}

/deep/ .upload-img-box .el-upload-list__item-thumbnail {
  height: 100% !important;
  display: block !important; /* 让图像成为块元素 */
  margin: 0 auto !important;
  object-fit: contain;
}

/deep/ .el-upload-list__item {
  vertical-align: middle !important;
  //display: table-cell !important;
  object-fit: contain;
  margin-right: 10px !important;

  > div {
    position: relative;
    width: 150px;
    height: 150px;
    border: 1px solid #ebeef5;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    > img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain; /* 图片按比例显示并居中 */
    }
  }
}

.top068 {
  top: 0.67rem !important;
}

.top250 {
  margin-top: 2.1rem !important;
}
</style>
