<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import "@wangeditor/editor/dist/css/style.css";
import { notifyMsg } from "common/js/util";
import { i18nChangeLanguage } from "@wangeditor/editor";

export default {
  props: {
    qiniuToken: {
      type: String
    },
    maxSize: {
      default: 5
    },
    placeholder: {
      type: String
    }
  },
  components: {
    Editor,
    Toolbar
  },
  data() {
    return {
      editor: null,
      content: "",
      toolbarConfig: {
        toolbarKeys: [
          "headerSelect",
          "bold",
          "italic",
          "underline",
          "color",
          "bgColor",
          "clearStyle",
          "bulletedList",
          "numberedList",
          "justifyLeft",
          "justifyRight",
          "justifyCenter",
          "insertLink",
          "|",
          {
            key: "group-image",
            menuKeys: ["uploadImage"],
            title: this.$t("quillEditor.img")
          },
          "|",
          "undo",
          "redo"
        ]
      },
      editorConfig: {
        placeholder: this.$t("quillEditor.placeholder"),
        MENU_CONF: {
          uploadImage: {
            customUpload: async (res, insertFn) => {
              const _params = await this.uploadQiniu(res);
              //  插入到文本框中
              insertFn(_params.url, _params.alt, _params.href);
            }
          }
        }
      },
      mode: "simple" // or 'default'
    };
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
    async uploadQiniu(file) {
      const _maxSize = Number(this.maxSize) * 1024 * 1024;
      if (file.size > _maxSize) {
        notifyMsg(this, "warning", `图片大小不能超过${this.maxSize}MB`);
        return Promise.reject(`图片大小不能超过${this.maxSize}MB`);
      }
      if (!file.type.startsWith("image/")) {
        notifyMsg(this, "warning", "只能上传图片");
        return Promise.reject("只能上传图片");
      }
      const params = await new Promise((resolve, reject) => {
        const formData = new FormData();
        // 在form实例中添加 file qiniuToekn 固定格式类名
        formData.append("file", file);
        formData.append("token", this.qiniuToken);
        const fileName = file.name.replace(/\s/g, ""); // 去除空格
        const ts = new Date().getTime();
        formData.append("key", `${ts}_${fileName}`);
        // 发起xhr请求
        const xhr = new XMLHttpRequest();
        xhr.open("POST", "https://upload.qiniup.com/", true);
        // 携带登录token
        xhr.setRequestHeader("Authorization", localStorage.getItem("token"));
        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            // 根据组件 api 返回固定格式的 url
            const response = JSON.parse(xhr.responseText);
            const url = `${this.$PicPrefix}/${response.key}`;
            const href = `${this.$PicPrefix}/${response.key}`;
            const alt = response.key;
            resolve({ url, href, alt }); // 将图片URL传递给富文本编辑器
          } else if (xhr.readyState === 4 && xhr.status !== 200) {
            reject("上传失败"); // 如果上传失败，返回错误信息
          }
        };
        xhr.send(formData);
      });
      console.log("params", params);
      return params;
    }
  },
  created() {
    const lang = localStorage.getItem("user_lang") === "en_US" ? "en" : "zh-CN";
    i18nChangeLanguage(lang);
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  }
};
</script>

<template>
  <div style="border: 1px solid #ccc;max-width: 800px;">
    <Toolbar
      style="border-bottom: 1px solid #ccc"
      :editor="editor"
      :defaultConfig="toolbarConfig"
      :mode="mode"
    />
    <Editor
      style="height: 200px; overflow-y: hidden;"
      v-model="content"
      :defaultConfig="editorConfig"
      :mode="mode"
      @onCreated="onCreated"
    />
  </div>
</template>

<style scoped lang="scss"></style>
